import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { IoMdClose } from "react-icons/io";
import { useLocation } from 'react-router-dom'; // Importando o useLocation

Modal.setAppElement('#root');

const WhatsAppModal = ({ isOpen, onRequestClose }) => {
  const location = useLocation();
  const [utmParams, setUtmParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const utms = {
      utm_source: searchParams.get('utm_source') || 'N/A',
      // utm_medium: searchParams.get('utm_medium') || 'N/A',
      // utm_campaign: searchParams.get('utm_campaign') || 'N/A',
      // utm_content: searchParams.get('utm_content') || 'N/A',
      // utm_term: searchParams.get('utm_term') || 'N/A',
    };
    setUtmParams(utms);
  }, [location.search]);

  const redirectToWhatsApp = (number) => {
    let message = '';

    if (utmParams.utm_source === 'instagram') {
      message = `
        Oi! Eu vi seu perfil no Instagram e gostaria de um orçamento de faxina!
      `;
    } else if (utmParams.utm_source === 'google') {
      message = `
        Oi! Vim diretamente do Google e gostaria de um orçamento de faxina!
      `;
    } else if (utmParams.utm_source === 'N/A' || utmParams.utm_source === undefined) {
      message = `
        Oi! Gostaria de um orçamento de faxina!
      `;
    }

    window.location.href = `http://wa.me/${number}?text=${encodeURIComponent(message)}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="flex items-center justify-center p-4 bg-white rounded-lg mx-auto w-11/12 sm:w-2/3 md:w-1/2 lg:w-1/3 max-w-lg h-auto"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
    >
      <div className="relative bg-white rounded-lg px-6 py-4 w-full">
        <div className='flex items-center justify-between mb-6'>
          <h2 className="text-lg font-semibold">Selecione a região do atendimento:</h2>
          <IoMdClose
            className="text-gray-600 text-2xl cursor-pointer hover:text-gray-800"
            onClick={onRequestClose}
          />
        </div>
        <button
          className="bg-gradient-to-tr from-[#DA5F78] to-[#EA889D] text-white py-3 w-full mb-4 rounded-xl"
          onClick={() => redirectToWhatsApp('5527999588009')}
        >
          São Paulo
        </button>
        <button
          className="bg-gradient-to-tr from-[#DA5F78] to-[#EA889D] text-white py-3 w-full mb-4 rounded-xl"
          onClick={() => redirectToWhatsApp('5527999588009')}
        >
          Espírito Santo
        </button>
        <button
          className="bg-gradient-to-tr from-[#DA5F78] to-[#EA889D] text-white py-3 w-full rounded-xl"
          onClick={() => redirectToWhatsApp('5531972457866')}
        >
          Belo Horizonte
        </button>
      </div>
    </Modal>
  );
};

export default WhatsAppModal;
