import PropTypes from 'prop-types';

const Button = ({ outline, label, onPress, type }) => {
  return (
    <button className="w-full pt-3 pb-3 bg-slate-100 rounded-xl bg-gradient-to-tr to-[#EA889D] from-[#DA5F78]" onClick={onPress}>
      <text className="text-white text-base">{label}</text>
    </button>
  )
}

Button.propTypes = {
  outline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

Button.defaultProps = {
  label: 'Label',
  type: 'Primary',
  onPress: () => {},
};

export default Button;