const InitialBannerStyle = {
  Container: 'w-full h-auto bg-gradient-to-tr to-[#F1E9E9] from-[#D2FFF4] lg:flex md:flex md:pb-32',
  ContentContainer: 'flex flex-col',
  LogoContainer: 'w-36 pt-10 pl-4 pr-4  md:w-48   lg:w-5/6 lg:pl-52 lg:pt-20',
  Logo: 'min-w-36 lg:w-72 ',
  TextContainer: 'flex flex-col w-full pt-4 pl-4 pr-4 md:w-96  lg:w-11/12 lg:pl-52',
  PrincipalTextItem: 'text-5xl font-bold lg:font-extrabold',
  DescriptionTextItem: 'mt-4 lg:font-semibold',
  ButtonContainer: 'w-40 mt-4 lg:w-52',
  ImageServiceContainer: 'sm:w-1/2 md:w-full md:flex md:flex-col md:justify-end md:-mb-32 lg:w-3/4 lg:flex lg:flex-col lg:justify-end xl:w-2/3 2xl:w-4/5',
  ImageService: 'w-full min-w-10 pr-2 lg:w-full xl:w-2/3 2xl:w-4/5'
}

const PartersStyle = {
  Container: "w-full mt-20 mb-10 lg:mt-56 xl:mt-20 2xl:mt-44 ",
  Background: 'w-[95%] rounded-r-[70px] bg-gradient-to-tr to-[#DA5F78] from-[#EA889D] lg:w-[90%] md:flex md:flex-row-reverse sm:flex-col lg:items-end sm:min-h-[40vh] lg:justify-around md:h-[34vh] lg:pr-24 xl:pr-24 lg:pb-2 xl:pb-0',
  TextsContainer: 'flex flex-col mt-5 w-auto pt-6 justify-center items-center lg:items-start lg:h-[50vh]',
  BannerBackground: 'flex flex-col bg-[#FBCBD5] mb-3 rounded-full w-80 pt-3 pb-3 pr-8 pl-8 items-center justify-center lg:w-auto',
  BannerText: 'text-[#DA5F78] font-bold text-base text-center',
  Title: 'w-64 text-3xl text-white font-bold mb-3 text-center lg:w-auto',
  SubTitle: 'text-white w-80 pr-4 text-sm ml-7 text-center lg:w-96 lg:text-left lg:ml-0',
  ImgContainer: 'flex flex-col justify-end items-center',
}

const WhatTheSolveeHaveStyle = {
  Container: 'w-full bg-[#E7FFF9] lg:p-0 mt-32 overflow-hidden relative top-0 left-0 mb-[-50px] ',
  Background: "w-full rounded-2xl flex flex-col lg:flex-row justify-between ",
  ImgContainer: ' p-3 flex lg:justify-end lg:items-center',
  Title: 'text-3xl mt-4 pl-10 pr-10 pb-7 text-center lg:pl-0 lg:text-start lg:pt-20 font-extrabold text-[#1A1818]',
  ItemsContainer: 'flex flex-row pb-7 lg:w-[70%]',
  ItemsCard: 'bg-white p-3 w-16 h-16 rounded-2xl shadow-lg',
  ItemsTitle: 'pb-3 text-[#DA5F78] text-xl font-extrabold',
}

const CleaningStyle = {
  Container: 'flex flex-col justify-between w-full h-auto mt-[-40px] md:pb-28 pl-6 pr-6 md:mt-[0px] 2xl:flex 2xl:pl-48 2xl:pr-48 2xl:mt-[-96px]  lg:flex md:justify-center md:-mt-20 md:flex-row',
  ItemContainer: 'flex flex-col items-center justify-center h-auto p-6 rounded-2xl bg-white 2xl:w-5/12 2xl:h-80 sm:w-11/1 md:w-11/1 md:ml-5 md:w-[45%] mt-4',
  ItemIconContainer: 'flex items-center justify-center w-20 h-20 bg-black rounded-2xl bg-gradient-to-tr to-[#D4CDE3] from-[#D3D7E8]',
  ItemIconContainerSecond: 'flex items-center justify-center w-20 h-20 bg-black rounded-2xl bg-gradient-to-tr to-[#F7D4C6] from-[#F1D8CA]',
  ItemIconImageContainer: 'w-12 h-auto',
  ItemIconImage: 'w-16 lg:w-16 2xl:w-16',
  ItemTextContainer: 'flex flex-col w-58 h-auto items-center 2xl:w-96',
  ItemTextTitle: 'text-3xl mt-4 font-extrabold text-[#694E9E]',
  ItemTextTitleSecond: 'text-3xl mt-4 font-bold text-[#ED9379]',
  ItemTextDescription: 'text-center mt-4 font-semibold',
  ButtonContainer: 'w-60 mt-4 lg:w-52 2xl:w-60'
}

const WhatTheSolveeMakeStyle = {
  Container: 'w-full h-auto pl-6 pr-6',
  InitialContainer: 'flex flex-col w-full h-auto items-center justify-center pt-4 pb-4',
  InitialLogoContainer: 'w-12 mb-4',
  InitialTitleText: 'flex flex-col text-center w-full h-auto items-center justify-center',
  TitleText: 'text-3xl font-extrabold mb-4',
  ItemsContainer: 'w-full h-auto md:flex md:flex-col md:items-center lg:flex lg:flex-row lg:justify-between pl-6 pr-6 xl:pl-44 xl:pr-44',
  ItemContainer: 'flex flex-col w-full h-auto bg-white rounded-2xl bg-white items-center justify-center p-8 mt-4 md:w-72 lg:w-80 lg:h-72 xl:w-3/12 xl:w-[30%]',
  ItemIconContainer: 'flex items-center justify-center w-20 h-20 rounded-2xl bg-gradient-to-tr to-[#F7D4C6] from-[#F1D8CA]',
  ItemIconContainerSecond: 'flex items-center justify-center w-20 h-20 rounded-2xl bg-gradient-to-tr to-[#EAF5F7] from-[#D2FFF4]',
  ItemIconContainerTertiary: 'flex items-center justify-center w-20 h-20 rounded-2xl bg-gradient-to-tr to-[#D7D5E7] from-[#D4CCE2]',
  ItemTextContainer: 'flex flex-col w-4/5 h-auto mt-4 lg:w-full xl:w-full',
  ItemTextTitle: 'text-3xl font-extrabold text-[#ED9379] text-center',
  ItemTextTitleSecond: 'text-3xl font-extrabold text-[#68C0CF] text-center',
  ItemTextTitleTertiary: 'text-3xl font-extrabold text-[#694E9E] text-center',
  ItemTextDescription: 'text-center font-medium mt-4'
}

const YouTooCanStyle = {
  Container: 'w-full h-auto pl-6 pr-6 xl:pl-48 xl:pr-48 mt-32 relative',
  ContainerBackground: 'w-full h-auto rounded-2xl  bg-gradient-to-tr to-[#7476B4] from-[#694E9E] pl-4 pr-4 pt-4 md:flex lg:pl-8 lg:pr-8 lg:pt-8 xl:justify-between',
  ContentContainer: 'w-full  h-auto xl:w-2/5',
  ContentTitleText: 'text-2xl text-white font-bold',
  ContentItem: 'w-full h-auto border-b-[1px] border-white border-opacity-10 mt-4 pb-4',
  ContentItemSecond: 'w-full h-auto mt-4 pb-4',
  ContentItemTitle: 'text-2xl text-[#68C0CF] font-bold',
  ContentItemTitleSecond:'text-2xl text-[#DA5F78] font-bold',
  ContentItemTertiary: 'text-2xl text-[#ED9379] font-bold',
  ContentItemDescription: 'text-white mt-4 ',
  ImageContainer: 'w-auto h-auto md:flex md:items-end xl:justify-end xl:w-2/5 pl-10',
  Image: 'xl:absolute xl:w-[600px]',
}

const PlansInfoStyle = {
  Container: 'w-full h-auto mt-16 pl-8 pr-8 md:flex md:items-start md:justify-between lg:pl-48 lg:pr-48 xl:mt-20',
  TextContentContainer: 'md:w-1/2',
  TitleContainer: 'w-full h-auto text-[#1A1818] text-center md:text-left md:w-80',
  Title: 'text-3xl font-bold md:text-2xl',
  DescriptionContainer: 'w-full mt-8 lg:w-3/5',
  DescriptionTitle: 'font-bold text-[#DA5F78] text-xl mb-2',
  Description: 'text-lg',
  PlansContainer: 'flex flex-col w-full h-auto items-center justify-center mt-8 md:mt-0 md:items-start md:w-80 lg:w-1/3',
  PlansContainerTitle: 'text-[#1A1818] text-3xl font-bold md:text-left',
  PlansItem: 'w-full h-auto bg-white rounded p-6 rounded-2xl mt-4',
  PlansItemTitle: 'font-bold text-2xl text-[#DA5F78] mb-2',
  PlansItemDescription: 'text-base',
  ButtonContainer: 'w-full mt-8 lg:w-72'
}

const VerifiedHouseKeeperStyle = {
  Container: 'lg:flex w-full h-auto items-center lg:justify-evenly pl-8 pr-8 mt-20 mb-32',
  Title: 'text-[#1A1818] text-2xl font-extrabold',
  SubTitle: 'text-[#1A1818] mt-5 mb-5',
  ImgContainer: 'grid grid-cols-3 gap-1 lg:gap-2 mt-10',
}

const RecommendationCardsStyle = {
  Container: 'w-full mt-10 mb-10',
  TitleContainer: 'flex justify-center mb-10',
  Title: 'text-[#1A1818] w-[80%] text-center text-2xl font-extrabold',
  CardsContainer: 'lg:flex justify-center',
  CardContainer: 'flex justify-center relative text-center mt-16 pl-4',
  CardBox: 'text-[#292929] flex flex-col justify-space items-center w-[315px] h-[300px]  bg-white p-5 rounded-2xl border-box hover:bg-gradient-to-r to-[#EA889D] from-[#DA5F78] hover:text-white hover:shadow-2xl',
  CardTitle: ' font-semibold mb-1',
  CardText: ' font-normal text-xs mb-8',
  CardComments: ' font-normal text-xs leading-6',
  CardImg: 'w-20 h-20 relative bottom-14 mb-[-40px]' ,
  Stars: 'flex absolute bottom-6 w-40 justify-around text-pink-700',
}

const AreYouReadyStyle = {
  Container: 'w-full h-auto',
  Body: 'text-center pb-20 pt-4 lg:pl-80 lg:pr-80 w-full',
  Title: 'text-[#1A1818] text-3xl font-extrabold mb-6',
  SubTitle: 'text-[#1A1818] font-medium mb-4 pr-14 pl-14'
}

const FooterStyle = {
  Container: 'flex flex-col w-full h-auto bg-[#694E9E] items-center justify-center pt-8 pb-8',
  ContentContainer: 'flex flex-col w-52 h-auto items-center',
  ImageLogoContainer: 'w-60',
  ImageLogo: 'w-60',
  ButtonsContainer: 'flex w-96 h-auto justify-between pr-20 pl-20',
  ButtonItem: 'flex w-10 h-10 rounded bg-gradient-to-tr to-[#EA889D] from-[#DA5F78] items-center justify-center',
  TextContainer: 'flex w-full items-center justify-center mt-8',
  Text: 'text-white text-xl cursor-pointer'
}

export { InitialBannerStyle, PartersStyle, RecommendationCardsStyle, WhatTheSolveeHaveStyle, VerifiedHouseKeeperStyle, CleaningStyle , AreYouReadyStyle, WhatTheSolveeMakeStyle, YouTooCanStyle, PlansInfoStyle, FooterStyle };