import { useEffect } from 'react';
import Button from '../../components/atoms/Button';

const Redirect = () => {
  useEffect(() => {
    setInterval(async function () {
      window.location.href = `http://wa.me/+5527999588009?text=${encodeURI('Oi! Vim diretamente do site e gostaria de um orçamento de faxina!')}`
    }, 3000);
  });

  return (
    <main className="w-full mt-24 flex flex-col justify-center items-center">
      <div className="flex flex-col items-center">
        <img className='w-[165px] h-[165px]' src="/images/check-blue.svg" alt="image"/>
        <h1 className='text-[#371E56] font-bold text-4xl mb-8'>Obrigado pela visita!</h1>
        {/* <h3>Serviço #{id}</h3> */}
        <h2 className='text-[#371E56] font-bold text-lg'>Aguarde enquanto redirecionamos você para o atendimento 😄!</h2>
        <div className="w-80 mt-20">
          <Button 
            label="Ir para o WhatsApp" 
            
          />
        </div> 
      </div>
    </main>
  )
}

export default Redirect