import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { RiInstagramFill, RiStarFill } from "react-icons/ri"
import Button from "../../components/atoms/Button";
import { InitialBannerStyle, PartersStyle, CleaningStyle, WhatTheSolveeMakeStyle, RecommendationCardsStyle, YouTooCanStyle, FooterStyle, PlansInfoStyle, VerifiedHouseKeeperStyle } from "./style";
import WhatsAppModal from "../../components/atoms/WhatsappModal";

const Faxina = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [utmParams, setUtmParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const utms = {
      utm_source: searchParams.get('utm_source') || 'N/A',
      // utm_medium: searchParams.get('utm_medium') || 'N/A',
      // utm_campaign: searchParams.get('utm_campaign') || 'N/A',
      // utm_content: searchParams.get('utm_content') || 'N/A',
      // utm_term: searchParams.get('utm_term') || 'N/A',
    };
    setUtmParams(utms);
  }, [location.search]);

  const redirectToWhatsApp = () => {
    let message;

    if (utmParams.utm_source === 'facebook') {
      message = `
        Oi! Eu vi seu perfil no Instagram e gostaria de um orçamento de faxina!
      `;
    } else if (utmParams.utm_source === 'google') {
      message = `
        Oi! Vim diretamente do Google e gostaria de um orçamento de faxina!
      `;
    } else if (utmParams.utm_source === 'N/A' || utmParams.utm_source === undefined) {
      message = `
        Oi! Gostaria de um orçamento de faxina!
      `;
    }

    window.location.href = `http://wa.me/5527999588009?text=${encodeURIComponent(message)}`;
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const InitialBanner = () => {
    return (
      <div>
        <section className={InitialBannerStyle.Container}>
          <div className={InitialBannerStyle.ContentContainer}>
            <div id="initial-banner-image-logo-container" className={InitialBannerStyle.LogoContainer}>
              <img src="./images/logo-solvee.svg" className={InitialBannerStyle.Logo} alt='logo-solvee' />
            </div>
            <div id="initial-banner-text-container" className={InitialBannerStyle.TextContainer}>
              <text className={InitialBannerStyle.PrincipalTextItem}><span className="text-[#68C0CF]">Contratar</span> um<br></br>profissional em faxina, nunca foi tão <span className="text-[#68C0CF]">fácil.</span></text>
              <text className={InitialBannerStyle.DescriptionTextItem}>Contrate de forma rápida, prática e segura um profissional de faxina para sua casa, escritório ou condomínio.</text>
              <div id="button-container" className={InitialBannerStyle.ButtonContainer}>
                <Button label='Contratar agora' onPress={() => redirectToWhatsApp()} />
              </div>
            </div>
          </div>
          <div id="initial-banner-image-container" className={InitialBannerStyle.ImageServiceContainer}>
            <img src="./images/initial-banner-image.png" alt="initial-banner" className={InitialBannerStyle.ImageService} />
          </div>
        </section>
        <CleaningOptions />
      </div>
    )
  }

  const Partners = () => {
    return (
      <section className={PartersStyle.Container}>
        <div className={PartersStyle.Background}>

          <div className={PartersStyle.TextsContainer}>
            <div className={PartersStyle.BannerBackground}>
              <span className={PartersStyle.BannerText}>Os melhores profissionais na palma da sua mão</span>
            </div>
            <span className={PartersStyle.Title}>Profissionais parceiros</span>
            <span className={PartersStyle.SubTitle}>Todos especialistas são verificados pela Solvee. Tudo para garantir qualidade e segurança na hora de executar um serviço.</span>
          </div>

          <div className={PartersStyle.ImgContainer}>
            <img src="./images/smiling-face.png" alt="smiling-face" className='absolute w-10 mb-48 ml-60' />
            <img src="./images/sms.png" alt="sms" className='absolute w-80 ml-10' />
            <img src="./images/smiling.png" alt="smiling" className='ml-10 min-w-10 lg:ml-0 md:max-w-[46vw]' />
            <img src="./images/green.png" alt="green" className='absolute w-14 mb-16 mr-72' />
          </div>
        </div>

      </section>
    )
  }

  const CleaningOptions = () => {
    return (
      <div className="flex">
        <div id="cleaning-option-section" className={CleaningStyle.Container}>
          <div className={CleaningStyle.ItemContainer}>
            <div className={CleaningStyle.ItemIconContainer}>
              <div class={CleaningStyle.ItemIconImageContainer}>
                <img src="./images/faxina-residencial-icon.svg" className={CleaningStyle.ItemIconImage} alt='cleaning-house' />
              </div>
            </div>
            <div className={CleaningStyle.ItemTextContainer}>
              <h2 className={CleaningStyle.ItemTextTitle}>Faxina residencial</h2>
              <span className={CleaningStyle.ItemTextDescription}>Continue com as tarefas do seu dia a dia, e mantenha o seu lar sempre limpo e aconchegante.</span>
              <div id="button-container" className={CleaningStyle.ButtonContainer}>
                <Button label='Quero uma faxina residencial' onPress={() => redirectToWhatsApp()} />
              </div>
            </div>
          </div>

          <div className={CleaningStyle.ItemContainer}>
            <div className={CleaningStyle.ItemIconContainerSecond}>
              <div class={CleaningStyle.ItemIconImageContainer}>
                <img src="./images/faxina-corporativa-icon.svg" className={CleaningStyle.ItemIconImage} alt='cleaning-company' />
              </div>
            </div>
            <div className={CleaningStyle.ItemTextContainer}>
              <h2 className={CleaningStyle.ItemTextTitleSecond}>Faxina corporativa</h2>
              <span className={CleaningStyle.ItemTextDescription}>Não perca o foco no seu trabalho. Deixe o seu escritório mais produtivo e organizado.</span>
              <div id="button-container" className={CleaningStyle.ButtonContainer}>
                <Button label='Quero uma faxina corporativa' onPress={() => redirectToWhatsApp()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const WhatTheSolveeMake = () => {
    return (
      <section className={WhatTheSolveeMakeStyle.Container}>
        <div className={WhatTheSolveeMakeStyle.InitialContainer}>
          <div className={WhatTheSolveeMakeStyle.InitialLogoContainer}>
            <img src="./images/solvee-logo-small.png" alt="solvee-logo" />
          </div>
          <div className={WhatTheSolveeMakeStyle.InitialTitleText}>
            <h2 className={WhatTheSolveeMakeStyle.TitleText}>O que a <b className="text-[#DA5F78]">Solvee</b> faz?</h2>
            <span>Nós <b>descomplicamos</b> a sua contratação de <b>especialistas em faxina.</b><br></br><br></br>
              Conectamos você com os <b>melhores profissionais</b> da área e <b>agilizamos</b> toda a parte burocrática.</span>
          </div>
        </div>

        <div className={WhatTheSolveeMakeStyle.ItemsContainer}>

          <div className={WhatTheSolveeMakeStyle.ItemContainer}>
            <div className={WhatTheSolveeMakeStyle.ItemIconContainer}>
              <img src="./images/phone-icon.svg" alt="phone-icon" />
            </div>
            <div className={WhatTheSolveeMakeStyle.ItemTextContainer}>
              <h2 className={WhatTheSolveeMakeStyle.ItemTextTitle}>Entre em contato</h2>
              <span className={WhatTheSolveeMakeStyle.ItemTextDescription}>Mande uma mensagem explicando o que precisa.</span>
            </div>
          </div>

          <div className={WhatTheSolveeMakeStyle.ItemContainer}>
            <div className={WhatTheSolveeMakeStyle.ItemIconContainerSecond}>
              <img src="./images/calendar-icon.svg" alt="calendar-icon" />
            </div>
            <div className={WhatTheSolveeMakeStyle.ItemTextContainer}>
              <h2 className={WhatTheSolveeMakeStyle.ItemTextTitleSecond}>Receba orçamentos</h2>
              <span className={WhatTheSolveeMakeStyle.ItemTextDescription}>Receba orçamentos direto no seu whatsapp.</span>
            </div>
          </div>

          <div className={WhatTheSolveeMakeStyle.ItemContainer}>
            <div className={WhatTheSolveeMakeStyle.ItemIconContainerTertiary}>
              <img src="./images/hand-icon.svg" alt="hand-icon" />
            </div>
            <div className={WhatTheSolveeMakeStyle.ItemTextContainer}>
              <h2 className={WhatTheSolveeMakeStyle.ItemTextTitleTertiary}>Aproveite!</h2>
              <span className={WhatTheSolveeMakeStyle.ItemTextDescription}> Agora o resto é todo com a gente!</span>
            </div>
          </div>
        </div>
      </section>
    )
  }

  const WhatTheSolveeHave = () => {

    const whatTheSolveeHave = [
      {
        title: "Orçamento gratuito",
        description: "Receba um orçamento gratuito e sem enrolações direto no seu whatsapp. Chega de complicações!", icon: './images/orcamento.svg'
      },
      {
        title: "Suporte humanizado",
        description: "A Solvee entende que imprevistos acontecem. Não gostou do serviço? Entre em contato com a gente!", icon: './images/certificado.svg'
      },
      {
        title: "Contratação fácil",
        description: "Defina data e horário. Receba uma proposta. Escolha a melhor. É muito fácil!", icon: './images/ferramentas.svg'
      }
    ]

    return (
      <section className="bg-[#e7fff9] w-full h-auto relative overflow-hidden mt-20">
        <div className="lg:flex bg-[86%] md:bg-[0%] lg:bg-[0%] 2xl:bg-[0%] w-auto h-auto bg-no-repeat" style={{ backgroundImage: "url('./images/fundo-azul.png')" }}>
          <div className="w-52 h-52 -mb-52 relative lg:w-72 lg:h-72">
            <img className="w-52 h-52 z-10 absolute -left-24 -top-24 lg:top-5 lg:-left-24 " src='./images/buble.png' alt="buble" />
          </div>
          <div className="md:flex lg:pt-10 lg:pb-10">
            <div className="p-3 relative">
              <img className="absolute top-16 left-24 lg:top-32 lg:left-32 lg:w-10" src="./images/✨.png" alt="stars" />
              <img className="absolute top-32 right-20 lg:top-52 lg:right-24 lg:w-10" src="./images/✨.png" alt="stars" />
              <img className="absolute w-16 -bottom-4 -left-4 lg:bottom-20 lg:-left-6 lg:w-24" src='./images/buble.png' alt="stars" />
              <img className="" src='./images/domestic.png' alt="house-keeper" />
            </div>

            <div className="lg:pt-20 lg:pl-5">
              <div className="relative lg:top-80 lg:-right-32">
                <img className="w-28 -right-12 -top-20 absolute opacity-25 lg:w-14 lg:right-10 lg:top-0" src="./images/buble.png" alt="buble" />
              </div>
              <div className="relative lg:top-80 lg:-right-32">
                <img className="w-16 -right-2 opacity-25 top-44 absolute lg:w-24 lg:right-0 lg:left-5 md:top-12" src="./images/buble.png" alt="buble" />
              </div>
              <p className="text-3xl pl-16 pr-16 mb-4  text-center font-extrabold text-[#1A1818] lg:text-start lg:p-0">Com a <span className='text-[#DA5F78]'>Solvee</span> você tem...</p>
              {whatTheSolveeHave.map((i) => (
                <div className="flex pl-3 pr-3 mb-5 lg:mb-10 lg:pl-0">
                  <div>
                    <div className='w-16 h-16 flex items-center justify-center bg-white rounded-2xl shadow-lg'>
                      <img width="40" height="40" src={i.icon} alt="icon-to-description" />
                    </div>
                  </div>
                  <div className="flex-col pl-5 ">
                    <p className='pb-3 text-[#DA5F78] text-xl font-extrabold'>{i.title}</p>
                    <p className='text-xs pr-10  text-[#1A1818] font-semibold'>{i.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="relative -mt-16 w-28 h-28 md:mt-0 lg:w-60 lg:h-60">
            <img className="w-full h-full absolute -left-10 -bottom-10  lg:bottom-0 lg:top-24 lg:left-24" src='./images/buble.png' alt="buble" />
          </div>
        </div>
      </section>
    )
  }

  const YouTooCan = () => {
    return (
      <section className={YouTooCanStyle.Container}>
        <div className={YouTooCanStyle.ContainerBackground}>
          <div className={YouTooCanStyle.ContentContainer}>
            <h2 className={YouTooCanStyle.ContentTitleText}>Você também pode...</h2>

            <div className={YouTooCanStyle.ContentItem}>
              <h2 className={YouTooCanStyle.ContentItemTitle}>Fazer planos recorrentes</h2>
              <span className={YouTooCanStyle.ContentItemDescription}>Precisa de uma faxina todos os meses? A cada 15 dias? Aqui você faz um plano de recorrência e recebe descontos exclusivos! </span>
            </div>

            <div className={YouTooCanStyle.ContentItem}>
              <h2 className={YouTooCanStyle.ContentItemTitleSecond}>Pagar mais tarde</h2>
              <span className={YouTooCanStyle.ContentItemDescription}>Pague só depois do serviço completo. O nosso trabalho é com a sua satisfação!</span>
            </div>

            <div className={YouTooCanStyle.ContentItemSecond}>
              <h2 className={YouTooCanStyle.ContentItemTertiary}>Pedir ajuda!</h2>
              <span className={YouTooCanStyle.ContentItemDescription}>Tem alguma dúvida? Aqui você é a <b>PRIORIDADE.</b> Manda uma mensagem para o nosso suporte!</span>
            </div>
          </div>

          <div className={YouTooCanStyle.ImageContainer}>
            <img src="./images/hands-holding.png" alt="hands-holding" className={YouTooCanStyle.Image} />
          </div>
        </div>
      </section>
    )
  }

  const PlansInfo = () => {
    return (
      <section className={PlansInfoStyle.Container}>
        <div className={PlansInfoStyle.TextContentContainer}>
          <div className={PlansInfoStyle.TitleContainer}>
            <h2 className={PlansInfoStyle.Title}>"E se eu precisar de uma faxina toda semana? 🤔"</h2>
          </div>
          <div className={PlansInfoStyle.DescriptionContainer}>
            <h2 className={PlansInfoStyle.DescriptionTitle}>A Solvee já pensou em tudo.</h2>
            <span className={PlansInfoStyle.Description}>É por isso que montamos dois planos pensando em você que SEMPRE precisa de uma faxina. É claro que adicionamos descontos exclusivos pra você!</span>
          </div>
        </div>
        <div className={PlansInfoStyle.PlansContainer}>
          <h2 className={PlansInfoStyle.PlansContainerTitle}>Planos Solvee:</h2>

          <div className={PlansInfoStyle.PlansItem}>
            <h2 className={PlansInfoStyle.PlansItemTitle}>Solvee 15</h2>
            <span className={PlansInfoStyle.PlansItemDescription}>Com o Solvee 15, você recebe uma faxina a cada 15 dias, e ainda ganha 10 reais de desconto por faxina.</span>
          </div>

          <div className={PlansInfoStyle.PlansItem}>
            <h2 className={PlansInfoStyle.PlansItemTitle}>Solvee 7</h2>
            <span className={PlansInfoStyle.PlansItemDescription}>Com o Solvee 7, você recebe um serviço de faxina toda semana e ganha 15 reais de desconto por faxina.</span>
          </div>

          <div className={PlansInfoStyle.ButtonContainer}>
            <Button label={'Quero um plano Solvee'} onPress={() => redirectToWhatsApp()} />
          </div>

        </div>
      </section>
    )
  }

  const VerifiedHouseKeeper = () => {

    const houseKeeper = [
      { image: './images/faxineira-1.png' },
      { image: './images/faxineira-2.png' },
      { image: './images/faxineira-3.png' },
      { image: './images/faxineira-4.png' },
      { image: './images/faxineira-5.png' },
      { image: './images/faxineira-6.png' }
    ]

    return (
      <section className={VerifiedHouseKeeperStyle.Container}>
        <div className='lg:w-[30%]'>
          <p className='text-[#1A1818] text-2xl lg:text-4xl font-extrabold'>A importância de contratar uma faxineira verificada</p>
          <p className='text-[#1A1818] mt-5 mb-5'>Garanta a qualidade e confiabilidade na limpeza da sua casa com profissionais selecionadas e treinadas.</p>
          <Button label="Quero um serviço de qualidade" onPress={() => redirectToWhatsApp()} />
        </div>

        <div className={VerifiedHouseKeeperStyle.ImgContainer}>
          {houseKeeper.map((i) => (
            <div className=''>
              <img width="150px" src={i?.image} alt="clean-person" />
            </div>
          ))}
        </div>
      </section>
    )
  }

  const RecommendationCards = () => {

    const recommendation = [
      {
        name: "Ana",
        profession: "Cliente Solvee",
        comment: "Como cliente sempre fui bem atendida em todas as minhas solicitações pelo whatsapp. As prestadoras que a empresa enviou fizeram o serviço adequado e no horário e dia determinado. Tanto, que já indiquei para várias pessoas!",
        img: './images/Ana.png'
      },
      {
        name: "Ingrid",
        profession: "Cliente Solvee",
        comment: "A experiência com a Solvee está sendo ótima, é bom ter a facilidade de agendamentos e saber que a profissional vai fazer um bom serviço. Traz tranquilidade. Recomendo.",
        img: './images/Ingrid.png'
      },
      {
        name: "Gemerson Machado", profession: "Cliente Solvee", comment: "O atendimento da Solvee se destaca na rapidez e agilidade com o cliente, estão sempre prontos para nos atender e tirar nossas dúvidas. Recomendo muito o serviço!",
        img: './images/gemerson.png'
      }
    ]

    return (
      <section className={RecommendationCardsStyle.Container}>
        <div className={RecommendationCardsStyle.TitleContainer}>
          <p className={RecommendationCardsStyle.Title}>Quem já contratou<span className='text-[#EA889D]'> recomenda</span></p>
        </div>

        <div className='lg:flex justify-center '>
          {recommendation.map(item => {
            return (
              <div className='flex justify-center relative text-center mt-16 pl-4' >
                <div className='text-[#292929] flex flex-col justify-space items-center w-[315px] h-auto bg-white p-5 rounded-2xl border-box hover:bg-gradient-to-r to-[#EA889D] from-[#DA5F78] hover:text-white hover:shadow-2xl'>

                  <img className='w-20 h-20 relative bottom-14 mb-[-40px]' src={item.img} alt="customer" />
                  <p className=' font-bold mb-1'>{item.name}</p>
                  <p className=' font-normal md:font-semibold text-xs mb-4'>{item.profession}</p>
                  <p className=' font-normal md:font-semibold text-md leading-6 mb-8'>{item.comment}</p>

                  <div className='flex absolute bottom-4 w-40 justify-around'>
                    <RiStarFill size={20} color={'#ED9379'} />
                    <RiStarFill size={20} color={'#ED9379'} />
                    <RiStarFill size={20} color={'#ED9379'} />
                    <RiStarFill size={20} color={'#ED9379'} />
                    <RiStarFill size={20} color={'#ED9379'} />
                  </div>

                </div>
              </div>
            )
          })}
        </div>

      </section>
    )
  }

  const AreYouReady = () => {
    return (
      <section className='flex flex-col w-full h-auto items-center mb-16'>
        <div className='w-80 lg:w-1/4 text-center'>
          <p className='text-[#1A1818] text-3xl font-extrabold mb-6'>Pronto para uma faxina?</p>
          <p className='text-[#1A1818] font-medium mb-4'>Mande agora uma mensagem para o nosso time e faça um orçamento gratuito em poucos minutos.</p>
        </div>
        <div className='w-80 lg:w-96'>
          <Button label="Solicitar Orçamento Gratuito" onPress={() => redirectToWhatsApp()} />
        </div>
      </section>
    )
  }

  const Footer = () => {
    return (
      <footer className={FooterStyle.Container}>
        <div className={FooterStyle.ContentContainer}>
          <div className={FooterStyle.ImageLogoContainer}>
            <img src="./images/logo-solvee-white.png" alt="solvee-logo" className={FooterStyle.ImageLogo} />
          </div>
          <div className={FooterStyle.ButtonsContainer}>
            <button className={FooterStyle.ButtonItem}>
              <FaFacebookF color='#fff' />
            </button>
            <button className={FooterStyle.ButtonItem}>
              <FaTwitter color='#fff' />
            </button>
            <button className={FooterStyle.ButtonItem}>
              <FaLinkedinIn color='#fff' />
            </button>
            <button className={FooterStyle.ButtonItem}>
              <RiInstagramFill color='#fff' />
            </button>
          </div>
        </div>

        <div className={FooterStyle.TextContainer}>
          <span className={FooterStyle.Text}>Acelerada pela <b onClick={() => window.location.href = 'https://turbopartners.com.br/'}>Turbo Partners</b></span>
        </div>

      </footer>
    )
  }

  return (
    <main className="w-full h-auto bg-[#FEFAFA]">
      <InitialBanner />
      <Partners />
      <WhatTheSolveeMake />
      <WhatTheSolveeHave />
      <YouTooCan />
      <PlansInfo />
      <VerifiedHouseKeeper />
      <RecommendationCards />
      <AreYouReady />
      <Footer />
      <WhatsAppModal isOpen={modalIsOpen} onRequestClose={closeModal}/>
    </main>
  )
}

export default Faxina;