import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Faxina from './pages/Faxina';
import Redirect from './pages/Redirect';

function Router(){
  return(
    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Faxina />} />
        <Route path='/redirect' exact element={<Redirect />} />
      </Routes>
    </BrowserRouter>
  )
} 

export default Router;